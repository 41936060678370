<template>
  <div>
    <vx-card>
      <div class="mb-8 md:flex" >
          <h2 class="mb-0">Page Management</h2>
          <div class="vx-col md:w-1/4 w-full md:ml-auto mt-10 md:mt-0" align="left">
          <vs-input class="w-full search-input" v-model="searchQuery" placeholder="Search..."/>
        </div>
      </div>

      <vs-table
        ref="table"
        :sst="true"
        :total="totalDocs"
        :max-items="dataTableParams.limit"
        :data="pageData"
        @search="handleSearch"
        @change-page="handleChangePage"
        @sort="handleSort"
      >
        <div
          slot="header"
          class="flex flex-wrap-reverse flex-grow justify-between mb-5"
        >
          <div style="float: left">
            <vs-select
              placeholder="10"
              autocomplete
              v-model="dataTableParams.limit"
              class="mr-6"
              label="display per page"
            >
              <vs-select-item
                :key="index"
                :value="item"
                :text="item"
                v-for="(item,index) in limitOptions"
                :clearable="false"
              />
            </vs-select>
          </div>
        </div>
        <template slot="thead">
          <vs-th>Page Title</vs-th>
          <vs-th>Created At</vs-th>
          <vs-th>Updated At</vs-th>
          <vs-th class="action-col justify-center">Action</vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].title">
              {{ data[indextr].title | capitalize}}
            </vs-td>
            <vs-td :data="data[indextr].createdAt">
              {{ moment(data[indextr].createdAt).format('DD/MM/YYYY') }}
            </vs-td>
            <vs-td :data="data[indextr].updatedAt">
              {{ moment(data[indextr].updatedAt).format('DD/MM/YYYY') }}
            </vs-td>
            <vs-td :data="data[indextr]._id" class="text-center">
              <a href="#" @click.stop="editDetailHandler(data[indextr]._id)">
                <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current"  />
              </a>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <div class="m-2" v-if="pageData.length > 0">
        <span class="mr-2">
          {{
          dataTableParams.page * dataTableParams.limit -
          (dataTableParams.limit - 1)
          }}
          -
          {{
            totalDocs - dataTableParams.page * dataTableParams.limit > 0
              ? dataTableParams.page * dataTableParams.limit
              : totalDocs
          }}
          of {{ totalDocs }}
        </span>
        <div class="pagination-div" v-if="serverResponded">
          <paginate
            :page-count="totalPage"
            :click-handler="handleChangePage"
            class="pagination"
            :page-range="9"
            :prevText="'<'"
            :nextText="'>'"
          ></paginate>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
  import { mapActions } from "vuex";
  import vSelect from "vue-select";
  import moment from "moment";

  export default {
    components: {
      "v-select": vSelect
    },
    data() {
      return {
        isMounted: false,
        totalDocs: 0,
        dataTableParams: {
          search: "",
          sort: "createdAt",
          dir: "desc",
          limit: 10,
          page: 1
        },
        pageData: [],
        limitOptions: ["5", "10", "25", "50", "100"],
        serverResponded: false,
        searchQuery: "",
        awaitingSearch: false,
      }
    },
    methods: {
      ...mapActions("pages", ["getPagesList"]),
      moment(date){
        return moment(date)
      },
      getPageListData() {
        this.dataTableParams.search = this.searchQuery;
        this.getPagesList(this.dataTableParams).then(res => {
          this.pageData = res.data.data.docs;
          this.totalDocs = res.data.data.pagination.total;
          this.page = res.data.data.pagination.page;
          this.serverResponded = true;
        })
      },
      handleSearch(searching) {
        this.dataTableParams.search = searching;
        this.dataTableParams.page = 1;
        this.$refs.table.currentx = 1;
        this.getPageListData();
      },
      handleChangePage(page) {
        this.dataTableParams.page = page;
        this.getPageListData();
      },
      handleSort(key, active) {
        this.dataTableParams.sort = key;
        this.dataTableParams.dir = active;
        this.dataTableParams.page = 1;
        this.$refs.table.currentx = 1;
        this.getPageListData();
      },
      editDetailHandler(id) {
        this.$router.push({name:'page-edit', params: { id: id } })
      },
    },
    computed: {
      totalPage: function() {
        return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
          ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
          : parseInt(this.totalDocs / this.dataTableParams.limit);
      },
    },
    watch: {
      "dataTableParams.page": function(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.dataTableParams.page = newVal;
          this.getPageListData();
        }
      },
      "dataTableParams.limit": function(newlimit, oldLimit) {
        if (newlimit !== oldLimit) {
          this.dataTableParams.page = 1;
          this.dataTableParams.limit = newlimit;
          this.getPageListData();
        }
      },
      searchQuery: function (val) {
        if (!this.awaitingSearch) {
          setTimeout(() => {
            this.getLearningCentreData();
            this.awaitingSearch = false;
          }, 1000); // 1 sec delay
        }
        this.awaitingSearch = true;
      },
    },
    created() {
      this.getPageListData();
    }
  }
</script>
